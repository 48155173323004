<template>
	<div>
		<div class="therapy-record-container grid-content bg-purple">
			<div ref="therapySourcePie" class="therapy-record-bar"
					 style=" height:384px"></div>
		</div>
	</div>
</template>

<script>
import {getReportFromSource} from '@/api/dashboard'
export default {
  
  name: "EchartPie",
	created () {
    this._getReportFromSource()
  },
  mounted(){
    this._initChartPieTherapySource()
    window.addEventListener("resize", this.chartInstancePieTherapySource.resize)
  },
  beforeDestroy(){
    window.removeEventListener('resize', this.chartInstancePieTherapySource)
  },
  watch:{
    fromSourceDate: {
      handler(newValue, oldValue) {
        this.diagnosisFrom = []
        this.patientFrom = []
        if (Object.keys(newValue).length) {
          this.diagnosisFrom = newValue['diagnosis_from']
          this.patientFrom = newValue['patient_from']
        }
        this.$nextTick(() => {
          this.chartInstancePieTherapySource.dispose()
          this._initChartPieTherapySource()
        })
      },
      immediate: true,
      deep: true,
    }
  },
  data() {
    return{
      fromSourceDate: {},
      diagnosisFrom: [{name:"患者场景",value:2389},{ name:"医生场景",value:0},{name:"慢病场景",value:172}], // 饼图的数据格式
      nowTime: this.$moment().format("YYYY年MM月DD日")
    }
  },
  methods: {
    _getReportFromSource(params) {
      this.listLoading = true
      getReportFromSource(params).then(response => {
        let data = response
        if (data.code === 200) {
          this.fromSourceDate = data.data
        }
        this.listLoading = false
      }).catch(error => {
        console.log(error);
        this.listLoading = false
      })
    },

    _initChartPieTherapySource() {
      const initOption = {
        color: ['#2362fb', '#fcc810', '#17e24b'],
        title: {
          text: `数据时间: ${this.nowTime}`,
          textStyle: {
            color: '#666',
          },
          left: '35%',
          bottom: '0'
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c} ({d}%)'
        },
        legend: {
          itemWidth: 40,
          itemHeight: 20,
          data: ['患者场景', '医管家', '慢病场景']
        },
        series: [{
          name: '问诊来源',
          type: 'pie',
          radius: ['43%', '62%'],
          center: ['50%', '50%'],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 10,
            borderColor: '#fff',
            borderWidth: 2
          },
          label: {
            show: true,
            position: 'outside'
          },
          emphasis: {
            label: {
              show: true,
              fontSize: '20',
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: true
          },
          data: this.diagnosisFrom
        }]
      }
      this.chartInstancePieTherapySource = this.$echarts.init(this.$refs.therapySourcePie)
      this.chartInstancePieTherapySource.setOption(initOption)
    },
  }
}
</script>

<style scoped>

</style>
